import { AppProps } from 'next/app'
import * as Sentry from '@sentry/node';
import LogRocket from 'logrocket';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    beforeSend(event) {
      const logRocketSession = LogRocket.sessionURL;
      if (logRocketSession !== null) {
        if (!event.extra) event.extra = {};
        event.extra!["LogRocket"] = logRocketSession;
        return event;
      } else {
        return event;
      }
    },
  });
}

if (typeof window !== 'undefined') {
  LogRocket.init('6fxqit/decidedly-uncouth');

  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });
  }
}

type AppError = {
  err: string;
}

export default function App({ Component, pageProps, err }: AppProps & AppError) {
  // Workaround for https://github.com/vercel/next.js/issues/8592
  return <Component {...pageProps} err={err} />;
}